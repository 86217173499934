<template>
  <div>
    <div class="columns">
      <div class="column">
        <p class="text-left text-2xl font-medium">Cuentas Bancarias</p>
      </div>
      <div class="column text-right">
        <b-button type="is-primary" @click="() => (is_modal_open = true)"
          >Agregar nueva cuenta</b-button
        >
      </div>
    </div>
    <div class="columns is-multiline mt-3">
      <div
        class="column text-left is-12"
        v-for="cuenta in getCuentasBancarias"
        :key="cuenta.id"
      >
        <div
          class="card shadow-none has-border-radius has-background-white-ter p-4"
        >
          <div class="columns">
            <div class="column is-6">
              <p class="has-text-grey">Institución</p>
              <p>{{ cuenta.institucion.nombre }}</p>
            </div>
            <div class="column">
              <p class="has-text-grey">Cuenta</p>
              <p>{{ cuenta.cuenta }}</p>
            </div>
            <div class="column">
              <p class="has-text-grey">CLABE</p>
              <p>{{ cuenta.CLABE }}</p>
            </div>
            <div class="column">
              <p class="has-text-grey">Moneda</p>
              <p>{{ cuenta.moneda.clave }}</p>
            </div>
            <div class="column is-1 text-right flex items-center justify-end">
              <b-dropdown
                aria-role="list"
                position="is-bottom-left"
                class="cursor-pointer"
              >
                <fa-icon icon="ellipsis-v" class="mr-4" slot="trigger" />
                <b-dropdown-item
                  aria-role="listitem"
                  @click="editCuentaBancaria(cuenta)"
                  class=""
                >
                  Editar
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  @click="deleteCuentaBancaria(cuenta)"
                  class="has-text-danger"
                >
                  Eliminar
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="is_modal_open"
        :active.sync="is_modal_open"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-cuenta-bancaria
          class=""
          :instituciones="getInstitucionesFinancieras"
          :cuenta="cuenta_edit"
        />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalCuentaBancaria from "@/components/modals/ModalCuentaBancaria";

export default {
  name: "CuentasBancarias",
  computed: {
    ...mapGetters("catalogos", ["getInstitucionesFinancieras", "getMonedas"]),
    ...mapGetters("empresa", ["getCuentasBancarias"])
  },
  data() {
    return {
      is_modal_open: false,
      cuenta_edit: null
    };
  },
  methods: {
    /*
      Cambia el booleano para abrir el modal y editar la cuenta bancaria
      @cuenta (Object)
    */
    editCuentaBancaria(cuenta) {
      this.cuenta_edit = cuenta;
      this.is_modal_open = true;
    },
    /*
      Función para eliminar cuenta bancaria
      @cuenta (Object)
    */
    deleteCuentaBancaria(cuenta) {
      this.$buefy.dialog.confirm({
        message: `Estas seguro que deseas eliminar la cuenta ${cuenta.cuenta}?`,
        cancelText: "Cancelar",
        confirmText: "Si, Eliminar",
        type: "is-danger",
        onConfirm: () =>
          this.$store.dispatch("empresa/deleteCuentaBancaria", cuenta.id)
      });
    }
  },
  components: {
    ModalCuentaBancaria
  },
  mounted() {
    if (this.getCuentasBancarias == null) {
      this.$store.dispatch("empresa/getCuentasBancarias");
    }
    if (this.getInstitucionesFinancieras == null) {
      this.$store.dispatch("catalogos/getInstitucionesFinancieras");
    }
  }
};
</script>
