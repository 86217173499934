<template>
  <validation-observer
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card">
      <section class="modal-card-head pb-0">
        <p class="font-bold text-2xl">
          {{
            cuenta == null
              ? "Agregar cuenta bancaria"
              : "Editar cuenta bancaria"
          }}
        </p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <div class="columns is-multiline">
          <div class="column is-12">
            <valid-auto-complete
              rules="required"
              label="Institución Financiera"
              placeholder="Buscar banco..."
              v-model="form.institucion_id"
              :data="instituciones"
              show="nombre"
              :expanded="true"
              :append-to-body="true"
            />
          </div>
          <div class="column is-12">
            <valid-input
              label="Cuenta"
              rules="required|numeric"
              placeholder="Introduzca la cuenta..."
              v-model="form.cuenta"
            />
          </div>
          <div class="column is-12">
            <valid-input
              label="CLABE"
              rules="required|length:18|numeric"
              placeholder="Introduzca la CLABE..."
              v-model="form.CLABE"
            />
          </div>
          <div class="column is-12">
            <valid-select
              rules="required"
              label="Moneda"
              placeholder="Selecciona uno..."
              v-model="form.moneda_id"
              fieldClass="has-text-grey-darker"
              :data="getMonedas"
              show="nombre"
              :expanded="true"
              realValue="id"
            />
          </div>
        </div>
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
          type="is-primary"
          @click="validate().then(result => createCuentaBancaria(result))"
        >
          {{
            cuenta == null
              ? "Agregar cuenta bancaria"
              : "Editar cuenta bancaria"
          }}
        </b-button>
      </section>
    </div>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidInput from "@/components/form/ValidInput";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";
import ValidSelect from "@/components/form/ValidSelect";

import { mapGetters } from "vuex";

export default {
  name: "ModalCuentaBancaria",
  components: {
    ValidationObserver,
    ValidInput,
    ValidAutoComplete,
    ValidSelect,
  },
  data() {
    return {
      form: {
        institucion_id: null,
        cuenta: null,
        CLABE: null,
        moneda_id: null,
      }
    };
  },
  props: {
    instituciones: Array,
    cuenta: [Object, null]
  },
  computed: {
    ...mapGetters("catalogos", ["getMonedas"]),
  },
  methods: {
    /*
      Crea una cuenta bancaria nueva y si ya existe la edita
      @res(Boolean) respuesta del validationobserver
    */
    createCuentaBancaria(res) {
      if (!res) return;
      if (!this.instituciones.find(x => x.nombre == this.form.institucion_id)) {
        this.notification(
          "warning",
          "El banco no es valido",
          "Por favor selecciona otro banco"
        );
      }
      let form = this.form;
      form.instituciones_financieras_id = this.instituciones.find(
        x => x.nombre == this.form.institucion_id
      ).id;
      if (this.cuenta == null) {
        this.$store.dispatch("empresa/addCuentaBancaria", form).then(() => {
          this.$parent.close();
        });
      } else {
        form.id = this.cuenta.id;
        this.$store.dispatch("empresa/editCuentaBancaria", form).then(() => {
          this.$parent.close();
        });
      }
    }
  },
  mounted() {
    if (this.cuenta != null) {
      this.form = {
        institucion_id: this.cuenta.institucion.nombre,
        cuenta: this.cuenta.cuenta,
        CLABE: this.cuenta.CLABE,
        moneda_id: this.cuenta.moneda_id,
      };
    }
    if (this.getMonedas.length == 0) {
      this.$store.dispatch("catalogos/getMonedas");
    }
  }
};
</script>
